var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-option-container" },
    [
      _c(
        "el-select",
        {
          attrs: {
            value: _vm.modelValue,
            size: _vm.size,
            filterable: _vm.filterable,
            disabled: _vm.disabled,
            placeholder: _vm.placeholder,
          },
          on: {
            change: function ($event) {
              _vm.$emit("change", _vm.formatVal($event, _vm.modifier))
            },
          },
        },
        [
          _vm._t("default"),
          _vm._l(_vm.data, function (item) {
            return _c("el-option", {
              key: item[_vm.value],
              attrs: { value: item[_vm.value], label: item[_vm.label] },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }